window.VWFS = window.VWFS || {};
VWFS.Datepicker = function (component) {

    var inputField;
    var errorMessage;

    /**
     * INIT
     */
    this.init = function () {
        inputField = component.querySelector("input");
        errorMessage = component.querySelector(".js-error-disabled-day");
        component.addEventListener('bronson_datepickerReady', onBronsonDatepickerReady);
        if (errorMessage) {
            //only add this event Listener if error Message is available, because the contact Form has hin own validation
            component.addEventListener("bronson_datepickerClose", onDatepickerClose);
        }
    }

    /**
     * ON bronson_datepickerReady
     */
    var onBronsonDatepickerReady = function (event) {
        /**
         * Skip the event loop on purpose.
         */
        setTimeout(function () {
            var flatpickr = event.target._flatpickr;
            var disable = getFlatpickrDisabledArray(flatpickr) || [];

            if (component.hasAttribute("data-days-disabled")) {

                // get a array of days to disable them.
                // like [0,6] for every Saturday and Sunday.
                var days = JSON.parse(component.getAttribute("data-days-disabled"));

                if (days && days.length > 0) {
                    var disableDays = function (date) {
                        if (date) {
                            for (var i = 0; i < days.length; i++) {
                                if (date.getDay() === days[i]) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                }
                disable.push(disableDays);
            }
            if (component.hasAttribute("data-dates-disabled")) {
                disable = [].concat(disable, JSON.parse(component.getAttribute("data-dates-disabled")));
            }

            flatpickr.set('disable', disable);
			VWFSUtils.dispatchEvent(component, 'nbw_datepickerReady');
        }, 0);
    }

    var onDatepickerClose = function () {
        var flatpickr = component._flatpickr;
        var disableRules = flatpickr.config.disable;
        var pickedDate = flatpickr.parseDate(inputField.value);
        var isDateDisabled = false;
        if (disableRules && disableRules.length > 0) {
            for (var i = 0; i < disableRules.length; i++) {

                var rule = disableRules[i];
                if (rule && typeof rule === "string" || typeof rule === "number" || rule instanceof Date) {
                    if (pickedDate.getTime() === flatpickr.parseDate(rule, undefined).getTime()) {
                        isDateDisabled = true;
                        break;
                    }
                } else if (rule && typeof rule === "object" && rule.from && rule.to) {
                    var from = flatpickr.parseDate(rule.from);
                    var to = flatpickr.parseDate(rule.to);
                    if ((pickedDate <= to && pickedDate >= from)) {
                        isDateDisabled = true;
                        break;
                    }
                } else if (rule && typeof rule === "function") {
                    if (rule(pickedDate)) {
                        isDateDisabled = true;
                        break;
                    }
                }
            }
        }
        if (isDateDisabled) {
            errorMessage.classList.remove("u-hide");
            inputField.classList.add("is-error");
        } else {
            errorMessage.classList.add("u-hide");
            inputField.classList.remove("is-error");
        }
    }

    var getFlatpickrDisabledArray = function (flatpickr) {
        var disable = [];

        var disableRules = flatpickr.config.disable;
        //remove all functions
        if (disableRules && disableRules.length > 0) {
            for (var i = 0; i < disableRules.length; i++) {

                var rule = disableRules[i];
                if (rule && typeof rule === "string" || typeof rule === "number" || rule instanceof Date) {
                    disable.push(rule);
                } else if (rule && typeof rule === "object" && rule.from && rule.to) {
                    disable.push(rule);
                } else if (rule && typeof rule === "function") {
                    //dont push to disable
                }
            }
        }
        return disable;
    }
}

/**
 * ON DOCUMENT LOADED
 */
document.addEventListener('DOMContentLoaded', function () {
    console.debug("INFO datepicker.js loaded");
    // ITERATE THROUGH ALL DATEPICKER-COMPONENTS AND INIT THEM
    document.querySelectorAll(".js-datepicker").forEach(
        function (component) {
            new VWFS.Datepicker(component).init();
        }
    );
});
